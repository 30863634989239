<template>
  <div>
    <FeeNotificationFilter @apply="getAllNotificationsList" />
    <NotificationReminderContainer
      :update-pagination="updatePagination"
      :is-loading-component="isLoadingComponent"
      :notification-actions="notificationActions"
      @update="typeAction"
      @filterRecord="filterRecord"
    />

    <!-- SCHEDULED NOTIFICATION -->
    <ScheduledNotificationCreateUpdate
      v-if="scheduleModel"
      :show-model="scheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :concerned="currentActionObject.concernedList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      @handleClick="toogleScheduleModel"
      @update="filterRecord"
    />
    <!-- UNSCHEDULED NOTIFICATION -->
    <UnScheduledNotificationCreateUpdate
      v-if="unScheduleModel"
      :show-model="unScheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      module="fee"
      @handleClick="toggleUnScheduleModel"
      @update="updateNotificationsList"
      @addNotificationAlert="addNotification"
    />
  </div>
</template>

<script>
import ScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import UnScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import notificationMixin from '@src/mixins/notification-mixin.js'
import NotificationReminderContainer from '@/src/components/Settings/notifications/NotificationReminderContainer.vue'
import FeeNotificationFilter from '@/src/components/fee/notifications/FeeNotificationFilter.vue'
/* Utils or mixins */
import { mapActions } from 'vuex'
import { getNotificationsOptionsList } from '@utils/notifications'
import NOTIFICATION_PATHS, {
  NOTIFICATION_RECIEVERS,
} from '@src/constants/notification-constants.js'

export default {
  components: {
    ScheduledNotificationCreateUpdate,
    UnScheduledNotificationCreateUpdate,
    NotificationReminderContainer,
    FeeNotificationFilter,
  },
  mixins: [notificationMixin],
  page: {
    title: 'Notification | Fee',
    meta: [
      {
        name: 'description',
        content: 'Fee Notification System',
      },
    ],
  },
  data() {
    return {
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.FEE,
      },
      optionList: {
        create_student_fee: [
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT,
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_MAIN_GUARDIAN,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_GUARDIANS,
        ],
        update_student_fee: [
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT,
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_MAIN_GUARDIAN,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_GUARDIANS,
        ],
        create_fee_type: [NOTIFICATION_RECIEVERS.SPECIFIC_STAFF],
        fee_received_acknowledgement: [
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT,
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_MAIN_GUARDIAN,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_GUARDIANS,
        ],
        fee_discount_added: [
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT,
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_MAIN_GUARDIAN,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_GUARDIANS,
        ],
        fee_fine_added: [
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT,
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_MAIN_GUARDIAN,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENT_GUARDIANS,
        ],
        create_section_fee: [
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENTS,
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENTS_MAIN_GUARDIANS,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENTS_GUARDIANS,
        ],
        update_section_fee: [
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENTS,
          NOTIFICATION_RECIEVERS.SPECIFIC_STAFF,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENTS_MAIN_GUARDIANS,
          NOTIFICATION_RECIEVERS.CONCERNED_STUDENTS_GUARDIANS,
        ],
        create_monthly_fee_generator: [NOTIFICATION_RECIEVERS.SPECIFIC_STAFF],
        update_monthly_fee_generator: [NOTIFICATION_RECIEVERS.SPECIFIC_STAFF],
      },
    }
  },

  methods: {
    setActionList() {
      this.notificationActions = {
        create_student_fee: {
          title: 'Reminder for Create Fee',
          label: 'Create Fee',
          feature: 'fee_manager',
          action: 'create_student_fee',
          loading: false,
          count: 0,
        },
        update_student_fee: {
          title: 'Reminder for Update Fee',
          label: 'Update Fee',
          feature: 'fee_manager',
          action: 'update_student_fee',
          loading: false,
          count: 0,
        },
        create_fee_type: {
          title: 'Reminder for Create Fee Type',
          label: 'Create Fee Type',
          feature: 'fee_manager',
          action: 'create_fee_type',
          loading: false,
          count: 0,
        },
        fee_received_acknowledgement: {
          title: 'Reminder for Fee Received Acknowledgement',
          label: 'Fee Received Acknowledgement',
          feature: 'fee_manager',
          action: 'fee_received_acknowledgement',
          loading: false,
          count: 0,
        },
        fee_discount_added: {
          title: 'Reminder for Fee Discount Added',
          label: 'Fee Discount Added',
          feature: 'fee_manager',
          action: 'fee_discount_added',
          loading: false,
          count: 0,
        },
        fee_fine_added: {
          title: 'Reminder for Fee Fine Added',
          label: 'Fee Fine Added',
          feature: 'fee_manager',
          action: 'fee_fine_added',
          loading: false,
          count: 0,
        },
        create_section_fee: {
          title: 'Reminder for Create Section Fee',
          label: 'Create Section Fee',
          feature: 'fee_manager',
          action: 'create_section_fee',
          loading: false,
          count: 0,
        },
        update_section_fee: {
          title: 'Reminder for Update Section Fee',
          label: 'Update Section Fee',
          feature: 'fee_manager',
          action: 'update_section_fee',
          loading: false,
          count: 0,
        },
        create_monthly_fee_generator: {
          title: 'Reminder for Create Monthly Fee Generator',
          label: 'Create Monthly Fee Generator',
          feature: 'fee_manager',
          action: 'create_monthly_fee_generator',
          loading: false,
          count: 0,
        },
        update_monthly_fee_generator: {
          title: 'Reminder for Update Monthly Fee Generator',
          label: 'Update Monthly Fee Generator',
          feature: 'fee_manager',
          action: 'update_monthly_fee_generator',
          loading: false,
          count: 0,
        },
      }
    },
    setConcernedAndOptionsList() {
      Object.keys(this.optionList).forEach((option) => {
        this.notificationActions[option].optionsList = getNotificationsOptionsList(
          this.optionList[option],
        )
      })
    },
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'NOTIFICATION_REMINDER',
          buttons: [
            {
              title: 'FEE_TYPE_NOTIFICATION',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'CREATE_FEE_TYPE',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.create_fee_type
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
            {
              title: 'SECTION_FEE_NOTIFICATION',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'CREATE_SECTION_FEE',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.create_section_fee
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'UPDATE_SECTION_FEE',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.update_section_fee
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
            {
              title: 'STUDENT_FEE_NOTIFICATION',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'CREATE_STUDENT_FEE',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.create_student_fee
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'UPDATE_STUDENT_FEE',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.update_student_fee
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },

            {
              title: 'FEE_RECEIVED_NOTIFICATION',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'RECEIVE_FEE',
                  action: {
                    func: () => {
                      this.currentActionObject =
                        this.notificationActions.fee_received_acknowledgement
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },

            {
              title: 'FEE_GENERATOR_NOTIFICATION',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'CREATE_MONTHLY_FEE',
                  action: {
                    func: () => {
                      this.currentActionObject =
                        this.notificationActions.create_monthly_fee_generator
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'UPDATE_MONTHLY_FEE',
                  action: {
                    func: () => {
                      this.currentActionObject =
                        this.notificationActions.update_monthly_fee_generator
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
            {
              title: 'FEE_RECEIVABLE_NOTIFICATION',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_FEE_DISCOUNT',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.fee_discount_added
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'ADD_FEE_FINE',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.fee_fine_added
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
